<mat-card appearance="outlined" class="log-in-card">
  <mat-card-header>
    <mat-card-title>Login</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <form [formGroup]="loginForm">
      <mat-form-field class="form-control">
        <mat-label>Email</mat-label>
        <input matInput
               type="email"
               class="form-control"
               formControlName="email"
               autocomplete="off">
      </mat-form-field>
      <mat-form-field class="form-control">
        <mat-label>Password</mat-label>
        <input matInput
               type="password"
               class="form-control"
               formControlName="password">
      </mat-form-field>
    </form>
    <!-- <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner> -->
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-footer>
    <mat-card-actions>
      <button mat-raised-button
              color="primary"
              type="submit"
              (click)="onLogin()">
        Login
      </button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>
