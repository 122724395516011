import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

export function AdminGuard(): boolean {
  const router = inject(Router);
  const userService = inject(UserService);

  const userRole = userService.user?.roles ? userService.user.roles[0] : '';
  if (userRole === 'ROLE_ADMIN') {
    return true;
  }
  router.navigate(['/kpi-statistics']);
  return false;
}
