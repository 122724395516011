import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CASTING_CONFIG } from '../resources/casting-config';
import { UserService } from '../services/user.service';

export function CastingGuard(): boolean {

  const router = inject(Router);
  const userService = inject(UserService);

  const userRole = userService.user?.roles ? userService.user.roles[0] : '';
  if (userRole === 'ROLE_ADMIN' && CASTING_CONFIG.enterprise) {
    return true;
  }
  router.navigate(['/kpi-statistics']);
  return false;
}
