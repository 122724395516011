import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

export function SalesUserGuard(): boolean {

  const router = inject(Router);
  const userService = inject(UserService);

  const userRole = userService.user.roles[0];
  const isAdmin = userRole === 'ROLE_ADMIN';
  const allowSalesUser = userRole === 'ROLE_ADMIN_SALES' && !!userService.user.allowedToCreateLabels;

  if (isAdmin || allowSalesUser) {
    return true;
  }

  router.navigate(['/kpi-statistics']);
  return false;
}
