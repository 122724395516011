import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export function AuthGuard(): boolean {
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);

  const isLoggedIn = authenticationService.isLoggedIn;
  if (isLoggedIn) {
    return true;
  }
  router.navigate(['/login']);
  return false;
}
