<mat-toolbar class="mat-elevation-z6"
             color="primary">
  <button mat-icon-button
          (click)="opened = !opened">
    <mat-icon>menu</mat-icon>
  </button> Admin portal
</mat-toolbar>
<mat-sidenav-container class="main-sidenav-container"
                       (backdropClick)="opened = !opened">
  <mat-sidenav class="sidenav"
               [mode]="mode"
               [opened]="opened"
               [fixedInViewport]="true"
               [fixedTopGap]="layoutGap">
    <mat-nav-list>
      <a mat-list-item
        [routerLink]="['/enterprise/list']" *ngIf="isAdmin && castingConfig.enterprise">Enterprises</a>
      <a mat-list-item
        [routerLink]="['/company/list']" *ngIf="isAdmin || allowSalesUser">Companies</a>
      <a mat-list-item
        [routerLink]="['/kpi-statistics']">View KPI & statistics</a>
      <a mat-list-item [routerLink]="['/sales-users']" *ngIf="isAdmin">Manage Sales users</a>
      <a mat-list-item
         (click)="logout()">Logout</a>
    </mat-nav-list>
  </mat-sidenav>
  <main>
    <router-outlet></router-outlet>
  </main>
</mat-sidenav-container>
<app-loader></app-loader>
