import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { IBreakpoints } from 'src/app/model/breakpoints.interface';
import { MatDrawerMode } from '@angular/material/sidenav';
import { UserService } from 'src/app/services/user.service';
import { CASTING_CONFIG } from 'src/app/resources/casting-config';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  mode: MatDrawerMode = 'over';
  opened = true;
  layoutGap = '64';
  fixedInViewport = true;
  isAdmin: boolean = false;
  allowSalesUser = false;
  castingConfig = CASTING_CONFIG;

  constructor(
    private bpo: BreakpointObserver,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const userRole = this.userService.user.roles[0];
    this.isAdmin = userRole === 'ROLE_ADMIN';
    this.allowSalesUser = userRole === 'ROLE_ADMIN_SALES' && !!this.userService.user.allowedToCreateLabels;

    const breakpoints = Object.keys(Breakpoints).map(key => Breakpoints[key as keyof IBreakpoints]);
    this.bpo.observe(breakpoints)
      .pipe(map(bst => bst.matches))
      .subscribe(matched => {
        this.determineSidenavMode();
        this.determineLayoutGap();
      });
  }

  private determineSidenavMode(): void {
    if (
      this.isExtraSmallDevice() ||
      this.isSmallDevice()
    ) {
      this.fixedInViewport = false;
      this.mode = 'over';
      this.opened = false;
      return;
    }

    this.fixedInViewport = true;
    this.mode = 'side';
  }

  private determineLayoutGap(): void {
    if (this.isExtraSmallDevice() || this.isSmallDevice()) {
      this.layoutGap = '0';
      return;
    }

    this.layoutGap = '64';
  }

  isExtraSmallDevice(): boolean {
    return this.bpo.isMatched(Breakpoints.XSmall);
  }

  isSmallDevice(): boolean {
    return this.bpo.isMatched(Breakpoints.Small);
  }

  logout(): void {
    this.authenticationService
      .logout()
      .subscribe((data: boolean) => {
        if (data) {
          this.router.navigate(['/login']);
        }
      });
  }

}
