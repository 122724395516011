import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export function LoggedInGuard(): boolean {
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);

  if (authenticationService.isLoggedIn) {
    router.navigate(['/company']);
    return false;
  }
  return true;
}
