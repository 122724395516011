import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  loaderVisible$!: Observable<boolean>;
  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderVisible$ = this.loaderService.loader;
  }
}
